/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

 var MySelect;
 var wpcf7Elm;


 

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.

  var $postContainer = $('.ajax_posts'),
      $loadMoreButton = $('.do-ajax'),
      offset = $postContainer.find('.type-post').length,
      paged = $loadMoreButton.attr('data-paged'),
      pt = $loadMoreButton.attr('data-pt'),
      time = null;



var myTimeline = new TimelineMax();
var breakPoint = 1024;

  myTimeline
  .add( TweenMax.fromTo($(".nav"), 0.5, {css: {y: "-100%"}}, {css:{y: "0%"}}, {ease: Circ.easeOut}) )
  .add( TweenMax.staggerFromTo($(".nav a"), 0.4, {css: {opacity: 0}}, {css:{opacity: 1}}, 0.05) );
  myTimeline.pause();



  function debounce(func, wait, immediate) {
      var timeout;
      return function() {
          var context = this, args = arguments;
          var later = function() {
              timeout = null;
              if (!immediate) {func.apply(context, args);}
          };
          var callNow = immediate && !timeout;
          clearTimeout(timeout);
          timeout = setTimeout(later, wait);
          if (callNow) {func.apply(context, args);}
      };
  }

  function makeTall(e){

    if ($(window).width() > breakPoint){
       var $this = $(this);
       $this.addClass('active');
       // var dd = $this.children('.sub-menu');
       $this.children('.sub-menu').fadeIn('fast');
       $('.dropdown-bar').fadeIn('fast');
       // console.log('test1');
    }


  }

  function makeShort(e){

    if ($(window).width() > breakPoint){
       var $this = $(this);
       var dd = $this.children('.sub-menu');
        $this.removeClass('active');
        $this.children('.sub-menu').fadeOut('fast');
        $('.dropdown-bar').fadeOut('fast');
        // console.log('test2');
    }

  }


  function runMyAjax(){ 
   //when the button is clicked run the ajax function
    var self = $(this);

     $.ajax({

         url: ajaxpagination.ajaxurl,
         method: 'POST',
         beforeSend: function ( xhr ) {
         xhr.setRequestHeader( 'X-WP-Nonce', ajaxpagination.nonce );

         },
         data: {
           action: 'ajax_pagination',
           offset: self.parents('.press-wrap').find('article').length,
           paged: self.attr('data-paged'),
           pt: self.attr('data-pt'),
           cat: self.attr('data-cat'),
           orderby:  self.attr('data-orderby'),
         },
         success: function (data) {

          self.text('Load More');
          self.removeClass('Loading');

          paged = self.attr('data-paged', parseInt(self.attr('data-paged')) + 1);
          self.parents('.press-wrap').children('.ajax_posts').append(data);
          self.attr('data-offset',self.parents('.press-wrap').children('.ajax_posts article').length);

            if (self.attr('data-paged') === self.parents('.press-wrap').children('.ajax_posts').attr('data-count')) {
                self.hide();
            }
            sr.sync();
    
         }
     });    


   }


  function runMyFilterAjax(){ 
   //when the button is clicked run the ajax function
    var self = $(this);



     $.ajax( {
         url: ajaxpagination.ajaxurl,
         method: 'POST',
         beforeSend: function ( xhr ) {
             xhr.setRequestHeader( 'X-WP-Nonce', ajaxpagination.nonce );
              $('.ajax_posts').fadeOut();
              $('.do-ajax').text('loading');
              $('.do-ajax').addClass('Loading');
         },
         data: {

           action: 'ajax_pagination',
           pt: 'thought-leadership',
           cat: self.attr('data-cat'),
           offset: self.attr('data-offset'),
           getAll: self.attr('data-getAll'),

         },
         success: function (data) {
           $('.ajax_posts').empty();  
           // console.log(data);
           console.log(data);
           $('.ajax_posts').append(data);
           $('.ajax_posts').fadeIn();
           $('.ajax_posts').attr('data-count',$('.article-wrap').attr('data-paged'));
           $('.do-ajax').attr('data-paged',1);

         },
         complete: function() {


           $('.do-ajax').text('Load More');
           $('.do-ajax').removeClass('Loading');
            sr.sync();


          if ($('.ajax_posts .article-wrap').length) {

            $('.ajax_posts').attr('data-count', $('.ajax_posts .article-wrap').attr('data-paged')); 

            if ($('.ajax_posts .article-wrap').attr('data-paged') <= 1) {

               $('.do-ajax').hide();

            } else {

               $('.do-ajax').show();

            }

          } else {

               $('.do-ajax').hide();

          }




         }
     });    

   }

   function barAnimation(){

     if ( $('.current-menu-ancestor , .current-menu-item').length) {

      TweenMax.to($('.bar'), 0.25, {css: {
        width: $('.current-menu-ancestor > a , .current-menu-item > a').width(),
        left:$('.current-menu-ancestor > a, .current-menu-item > a').position().left,
        top: $('.phone-section').outerHeight()
      }});

     } else {
       $('.bar').width(0);
     }
     
   }


function getRole() { 

    $('.member-form-wrap').attr('data-role',$('.chooser .active').data('role'));

}


  var Sage = {
    // All pages
    'common': {
      init: function() {


        $('.scroll-down').on('click',function(e){

            e.preventDefault();
            $('html, body').animate({ scrollTop: ($('.hero').outerHeight()-60)+'px' });

        });

        $('.single-groups .scroll-down').on('click',function(e){

            e.preventDefault();
            $('html, body').animate({ scrollTop: $('.entry-content').offset().top - 140 });

        });




        $('.social-s').on('click', function () {
          window.open($(this).attr('href'), 'social-sharing', 'width=632,height=253,status=0,toolbar=0,menubar=0,location=1,scrollbars=1');        
          return false;
        });

        $(".grow-number-wrap:contains('%')").html(function(_, html) {
           return html.replace(/(%)/g, '<span class="percent">$1</span>');
        });

        $(".benchmark-number-wrap:contains('%')").html(function(_, html) {
           return html.replace(/(%)/g, '<span class="percent">$1</span>');
        });

        $(".big-number:contains('%')").html(function(_, html) {
           return html.replace(/(%)/g, '<span class="percent">$1</span>');
        });

        $('#select-beast').selectize({
          create: false ,
          sortField: {
            field: 'text',
            direction: 'asc'
          },
          dropdownParent: 'body',
          onInitialize: function(){

              if ($('.selectize-input .item').length) {
                $('.go-btn').removeClass('non-active');

              }

          },
          onType:  function(str) { str || this.$dropdown_content.removeHighlight(); },
          onChange: function(value){

            if (!value.length) {return;}

            $('.member-form-wrap').attr('data-group',value);
            $('.go-btn, .cta-menu').removeClass('non-active');




          },
          onItemAdd: function(value, item){

            console.log('test');

            $('.member-form-wrap').attr('data-group',value);
            $('.go-btn, .cta-menu').removeClass('non-active');


          }
        });

        var selector = '.sr, .entry-content *, .team header';

        // // JavaScript to be fired on all pages
        window.sr = new ScrollReveal();
        sr.reveal(selector,  {
          distance: '0px',
          scale: 1,
          duration: 600,
          delay: 100,
        });


        $('.chooser a').on('click',function(e){
          e.preventDefault();
           $('.chooser a').removeClass('active');
           $(this).addClass('active');
           getRole();

        });


        $('.employer-agency .go-btn').on('click',function(e){
            
            e.preventDefault();
            var group = $('.selectize-input.has-items .item');
            window.location.href = 'https://dhccontributions.com/'+group.attr('data-value')+'/TPALogin.aspx'+'?role='+$('.member-form-wrap').attr('data-role');

        });

        $('.employee-members .go-btn').on('click',function(e){
            
            e.preventDefault();
            var group = $('.selectize-dropdown .option.selected');

            if ($('.selectize-input .item').length) {

                window.location.href = $('.selectize-input .item').attr('data-value');

            }
              else {
                   window.location.href = group.attr('data-value');
              }
            
           

        });

        getRole();



        $('.cta-section .dropdown').on('click',function(e){

            e.preventDefault();

            $(this).toggleClass('expanded');

        });


        $('.featured-item[data-num="1"], .post-nav-btn[data-num="1"]').addClass('active');


        $("#nav-icon3").click(function() {

          $("#nav-icon3").toggleClass('open');
          $('html, body').toggleClass('modal-open');
          
          if ($('.nav').hasClass("active")) {

               $(".nav").removeClass("active");
               myTimeline.reverse();

          } else if (!$('.nav').hasClass("active")) {

           $('.nav').addClass("active");
            
               myTimeline.play();

          }

        });


         $(document).on('click', '.do-ajax', function(e) {

          e.preventDefault();
          $(this).text('Loading');
          $(this).addClass('Loading');
          runMyAjax.bind(this)();

         });
       


          if ( $('.wpcf7-select').length ) {

           MySelect = new Select({
             el: $('.wpcf7-select')[0],
             className: 'select-theme-dark',
              useNative: false
           }); 

           $('.widget .Industry .select-target').html("Industry <b></b>");

           $('.select-options, .select-element').width($('.select-target').outerWidth());
           
             MySelect.on('open',function(){
                 $(this).addClass('active');
                 // $(this).css('marginBottom',$('.select-options').height());
                 $('.select-options, .select-element').width($('.select-target').outerWidth());
             }, $('.select-target'));

             MySelect.on('close',function(){
                 $(this).removeClass('active');
                 $(this).css('marginBottom',0);
                 $('.select-options, .select-element').width($('.select-target').outerWidth());
             }, $('.select-target'));         

         }


      },
      finalize: function() {



        var buttonText;
        $('.wpcf7-submit').on('click', function(event) {

          buttonText = $(this).val();
          $(this).val('LOADING');

        });

        var wpcf7Elm = document.querySelector( '.wpcf7' );


        if ( wpcf7Elm ) { 


          var siteHost = '';

           if (window.location.hostname === 'localhost') {
            siteHost = '/dhc';
           }


          wpcf7Elm.addEventListener( 'wpcf7submit', function( event ) {

            this.childNodes[3][11].value = buttonText;

          }, false );


          wpcf7Elm.addEventListener( 'wpcf7mailsent', function( event ) {

             window.location.href = siteHost+'/thank-you/';
              
          }, false );


        } 
         






        $(window).on('resize',function(){

          $('.select-options, .select-element').width($('.select-target').outerWidth());

          if ($(window).innerWidth() >= breakPoint){


            $('.article-wrap .dropdown-toggle').on('mouseover',function(){
                 $(this).parent('.dropdown').addClass('show');
                  $(this).attr({
                    'aria-expanded': 'true',  
                  });

            });

            $('.article-wrap .dropdown').on('mouseleave',function(event) {
              // /* Act on the event */
                $(this).removeClass('show');
                $(this).find('.dropdown-toggle').attr({
                  'aria-expanded': 'false',  
                });
            });


            barAnimation();


            $('.nav > .menu-item > a').on('mouseover',function(){

              var self = $(this);

              if ( self.parent().hasClass('menu-item-has-children') ) {

                  
                   self.addClass('active');
                   $('.sub-menu').hide();
                   // var dd = $this.children('.sub-menu');
                  self.next('.sub-menu').show();
                   $('.dropdown-bar').fadeIn('fast');


              } else {

                   $('.sub-menu').hide();
                   $('.dropdown-bar').fadeOut('fast');

              }


              TweenMax.to($('.bar'), 0.25, {css: {top:40, width: self.width(), left: self.position().left,}});


            });

            

            $('.main, .hero,.content, .conversion').on('mouseover',function(){

               $('.sub-menu').hide();
               $('.dropdown-bar').fadeOut('fast');

               barAnimation();

            });

          

        


          } else {

           $('.bar').width(0);

          }

        }).resize();




        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS


      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'lets_talk': {
      init: function() {
        // JavaScript to be fired on the contact us page



        $(function initMap() {
          var mapDiv = document.getElementById('map');
          var uluru = {lat: 40.752661, lng: -73.992139};
          var map = new google.maps.Map(mapDiv, {
            center:  uluru,
            zoom: 16
          });
          var marker = new google.maps.Marker({
            position: uluru,
            map: map
          });


          var contentString = 
                     '<div id="map-info-content">'+
                     '<h5>Daniel H. Cook Associates Inc.</h5>'+
                     // '<h6>Trusted as third-party administrators for more than 40 years.</h6>'+
                     '<hr>'+
                     '<p>253 West 35th Street, 12th Floor</p>'+
                     '<p>New York, NY 10001-1907</p>'+
                     '</div>';

         var infowindow = new google.maps.InfoWindow({
           content: contentString,
           maxWidth: 400
         });

         infowindow.open(map, marker);

         marker.addListener('click', function() {
           infowindow.open(map, marker);
         });
        });
          
       // var MySelect;

       // if ( $('.wpcf7-select').length ) {

       //  MySelect = new Select({
       //    el: $('.wpcf7-select')[0],
       //    className: 'select-theme-dark',
       //     useNative: false
       //  }); 



      },
       finalize: function() {



       }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);


})(jQuery); // Fully reference jQuery after this point.
